
import { filter } from "~/composables/useFilter";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    height: {
      type: String,
      default: "16rem",
    },
  },
  computed: {
    filter() {
      return filter;
    },
  },
};
